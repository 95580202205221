import { Injectable } from '@angular/core';
import { LoaderInterceptorComponent } from '../shared-modules/components/loader-interceptor/loader-interceptor.component';

@Injectable({
    providedIn: 'root',
})
export class InterceptorLoaderService {
    private loaderComponent: LoaderInterceptorComponent | null = null;

    register(loaderComponent: LoaderInterceptorComponent): void {
        this.loaderComponent = loaderComponent;
    }

    show(): void {
        // this.loaderComponent?.show();
    }

    hide(): void {
        this.loaderComponent?.hide();
    }
}
