import { Component } from '@angular/core';

@Component({
  selector: 'app-importent-deadline',
  templateUrl: './importent-deadline.component.html',
  styleUrls: ['./importent-deadline.component.scss']
})
export class ImportentDeadlineComponent {

}
