import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketChatService extends Socket {
  constructor() {
    super({
      url: environment.chatApi,
      options: {
        transports: ['websocket'],
        reconnection: false,
        autoConnect: false, // Prevent auto-connection
        forceNew: true,
        timeout: 5000,
      },
    });
  }

  connectSocket() {
    if (!this.ioSocket.connected) {
      this.ioSocket.connect();
      console.log('Chat socket connected');
    }
  }

  disconnectSocket() {
    if (this.ioSocket.connected) {
      this.ioSocket.disconnect();
      console.log('Chat socket disconnected');
    }
  }
}
