import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class InterceptorLoaderService {
    private requestCount = 0;

    public showLoader(): void {
        this.requestCount++;
        if (!Swal.isVisible()) {
            Swal.fire({
                allowOutsideClick: false,
                showConfirmButton: false,
                background: 'rgb(192, 192, 192, 0)',
                didOpen: () => {
                    if (!Swal.isLoading()) {
                        Swal.showLoading();
                    }
                },
            });
        }
    }

    public hideLoader(): void {
        if (this.requestCount > 0) {
            this.requestCount--;
        }
        if (this.requestCount === 0 && Swal.isVisible()) {
            if (Swal.isLoading()) {
                Swal.hideLoading();
            }
            Swal.close();
        }
    }
}
