import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { SocketNotification } from 'src/app/app.module';
import { SocketChatService } from '../socket-chat.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor(
        private socket: SocketChatService,
        private socketnotification: SocketNotification
    ) {}

    createConnection() {
        this.socket.connectSocket();
    }
    listenToChannel(channel: string): Observable<any> {
        return this.socket.fromEvent(channel);
    }

    // Emit data to a specific channel
    emitToChannel(channel: string, data: any) {
        this.socket.emit(channel, data);
    }

    customSocketOn(event: string): Observable<any> {
        return new Observable((observer) => {
            this.socket.on(event, (data: any) => {
                observer.next(data);
            });
            // Clean up the event listener on unsubscribe
            return () => this.socket.off(event);
        });
    }

    // Optionally, disconnect from the socket server
    disconnect() {
        this.socket.disconnect();
    }

    // Reconnect to the socket server
    reconnect() {
        this.socket.connect();
    }

    // For notification
    listenToTwoChannel(channel: string): Observable<any> {
        return this.socketnotification.fromEvent(channel);
    }

    // Emit data to a specific channel
    emitToChatChannel(channel: string, data: any) {
        this.socketnotification.emit(channel, data);
    }

    // Optionally, disconnect from the socket server
    disTwoconnect() {
        this.socketnotification.disconnect();
    }

    // Reconnect to the socket server
    reconnectTwo() {
        this.socketnotification.connect();
    }

    customTwoSocketOn(event: string): Observable<any> {
        return new Observable((observer) => {
            this.socketnotification.on(event, (data: any) => {
                observer.next(data);
            });
            // Clean up the event listener on unsubscribe
            return () => this.socketnotification.off(event);
        });
    }
}
