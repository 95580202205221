import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { ComponentRef } from '@angular/core';

interface StoredRouteInfo {
    handle: DetachedRouteHandle;
    initialized: boolean;
}

export class DynamicRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, StoredRouteInfo>();
    private cachedPaths: Set<string> = new Set();
    private isEnabled = true;

    constructor() {
    }

    public addPathToCache(path: string): void {
        this.cachedPaths.add(path);
    }

    public removePathFromCache(path: string): void {
        this.cachedPaths.delete(path);
        this.storedRoutes.delete(path);
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getRouteUrl(route);
        return this.isEnabled && this.cachedPaths.has(path);
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const path = this.getRouteUrl(route);
        if (this.isEnabled && this.cachedPaths.has(path) && handle) {
            const componentRef = (handle as any)?.componentRef as ComponentRef<any>;
            if (componentRef?.instance) {
                componentRef.instance.isInitialized = true;
            }
            this.storedRoutes.set(path, {
                handle,
                initialized: true
            });
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getRouteUrl(route);
        return this.isEnabled &&
            this.cachedPaths.has(path) &&
            this.storedRoutes.has(path);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const path = this.getRouteUrl(route);
        console.log('Retrieving from cache:', path);

        if (!this.isEnabled || !this.cachedPaths.has(path)) {
            return null;
        }

        const storedRouteInfo = this.storedRoutes.get(path);
        if (!storedRouteInfo) {
            return null;
        }

        const componentRef = (storedRouteInfo.handle as any)?.componentRef as ComponentRef<any>;
        if (componentRef?.instance) {
            componentRef.instance.isReused = true;
            componentRef.instance.isInitialized ||= storedRouteInfo.initialized;
            console.log('Component reused:', path);
        }

        return storedRouteInfo.handle;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (!this.isEnabled) {
            return false;
        }

        const futurePath = this.getRouteUrl(future);
        const currentPath = this.getRouteUrl(curr);

        if (this.cachedPaths.has(futurePath) || this.cachedPaths.has(currentPath)) {
            return futurePath === currentPath;
        }

        return future.routeConfig === curr.routeConfig;
    }

    private getRouteUrl(route: ActivatedRouteSnapshot): string {
        return route?.routeConfig?.data?.['componentName'] || '';
    }

    public clearCache(): void {
        this.storedRoutes.clear();
    }

    public disable(): void {
        this.isEnabled = false;
        this.clearCache();
    }

    public enable(): void {
        this.isEnabled = true;
    }
}