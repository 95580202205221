import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { SpecialCharPipe } from './core/pipe/special-char.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { Socket } from 'ngx-socket-io';
import { ChatModule } from './views/chat/chat.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { environment } from 'src/environments/environment';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { LoaderInterceptorComponent } from './core/shared-modules/components/loader-interceptor/loader-interceptor.component';
import { LocalStorageService } from './core/services/localStorage/localStorage.service';
import { RouteReuseStrategy } from '@angular/router';
import { DynamicRouteReuseStrategy } from './core/helpers/single-component-route-reuse-strategy';
import { ImportentDeadlineComponent } from './views/importent-deadline/importent-deadline.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

// const config: SocketIoConfig = {
//     url: environment.chatApi,
//     options: {
//         transports: ['websocket'],
//     },
// };

@Injectable()
export class SocketChat extends Socket {
    private static instance: SocketChat | null = null;
    private initialized = false;
    private eventSubscriptions: { event: string; callback: Function }[] = [];
    private isConnected = false;
    private isPremium = false;
    private allowConnection = false;

    constructor(private _localStorageService: LocalStorageService) {
        if (SocketChat.instance) {
            return SocketChat.instance;
        }

        // Check premium status before super
        const userDetails = _localStorageService.getUser();
        const isPremiumUser = userDetails?.isPremium === 'true';

        super({
            url: environment.chatApi,
            options: {
                transports: ['websocket'],
                reconnection: false,
                autoConnect: false,  // Prevent auto-connection
                forceNew: true,      // Force new connection
                timeout: 5000        // Add timeout
            },
        });

        // Immediately disconnect if not premium
        if (!isPremiumUser) {
            this.forceDisconnect();
        }

        this.isPremium = isPremiumUser;

        // Only set up handlers if premium
        if (this.isPremium) {
            this.setupConnectionHandlers();
            this.setupVisibilityHandler();
        }

        SocketChat.instance = this;
    }

    private setupConnectionHandlers(): void {
        // Add connection interceptor
        const originalConnect = this.connect.bind(this);
        this.connect = () => {
            if (!this.allowConnection || !this.isPremium) {
                console.log('Chat connection blocked: Not authorized');
                return this;
            }
            return originalConnect();
        };

        this.on('connect', () => {
            if (!this.isPremium || !this.allowConnection) {
                console.log('Unauthorized chat connection detected, disconnecting...');
                this.cleanup();
                return;
            }
            console.log('Chat socket connected');
            this.isConnected = true;
            this.reattachEventListeners();
        });

        this.on('disconnect', () => {
            console.log('Chat socket disconnected');
            this.isConnected = false;
            this.allowConnection = false;  // Reset connection flag
        });

        this.on('connect_error', (error: any) => {
            console.error('Chat socket connection error:', error);
            this.cleanup();
        });
    }

    private setupVisibilityHandler(): void {
        if (typeof document !== 'undefined') {
            document.addEventListener('visibilitychange', () => {
                if (!this.isPremium) return;  // Early return if not premium
                
                if (document.visibilityState === 'visible') {
                    this.reconnect();
                } else {
                    this.cleanup();
                }
            });
        }
    }

    private forceDisconnect(): void {
        this.disconnect();
        this.removeAllListeners();
        // Force the socket to disconnect by destroying the connection
        if ((this as any).io) {
            (this as any).io.destroy();
        }
    }

    initialize(): boolean {
        if (this.initialized) {
            return true;
        }

        if (!this.isPremium) {
            console.log('Chat socket not initialized: User is not premium');
            return false;
        }

        try {
            this.allowConnection = true;  // Only allow connection through initialize
            this.connect();
            this.initialized = true;
            return true;
        } catch (error) {
            console.error('Failed to initialize chat socket:', error);
            this.allowConnection = false;
            return false;
        }
    }

    safeOn(event: string, callback: Function): void {
        if (!this.isPremium) {
            console.log('Chat event subscription ignored: User is not premium');
            return;
        }
        this.eventSubscriptions.push({ event, callback });
        this.on(event, callback as any);
    }

    safeOff(event: string): void {
        this.removeAllListeners(event);
        this.eventSubscriptions = this.eventSubscriptions.filter(sub => sub.event !== event);
    }

    private reattachEventListeners(): void {
        if (!this.isPremium) return;

        this.eventSubscriptions.forEach(sub => {
            this.removeAllListeners(sub.event);
            this.on(sub.event, sub.callback as any);
        });
    }

    reconnect(): void {
        if (!this.isPremium) {
            console.log('Chat reconnect ignored: User is not premium');
            return;
        }

        if (!this.isConnected && this.initialized) {
            this.cleanup();
            this.allowConnection = true;  // Re-enable connection for reconnect
            this.connect();
        }
    }

    cleanup(): void {
        this.allowConnection = false;  // Prevent new connections
        this.forceDisconnect();
        this.eventSubscriptions = [];
        this.isConnected = false;
    }

    static clearInstance(): void {
        if (SocketChat.instance) {
            SocketChat.instance.cleanup();
            SocketChat.instance = null;
        }
    }
}
@Injectable()
export class SocketNotification extends Socket {
    private static instance: SocketNotification | null = null;
    private initialized = false;
    private eventSubscriptions: { event: string; callback: Function }[] = [];
    private isConnected = false;
    private isPremium = false;

    constructor(private _localStorageSetive: LocalStorageService) {
        if (SocketNotification.instance) {
            return SocketNotification.instance;
        }

        super({
            url: environment.notificationApi,
            options: {
                transports: ['websocket'],
                reconnection: false,
                autoConnect: false  // This is crucial - prevent auto-connection
            },
        });

        const userDetails = _localStorageSetive.getUser();
        this.isPremium = userDetails?.isPremium ? userDetails?.isPremium === 'true' : false;
        console.log('Premium status:', this.isPremium);
        
        this.setupConnectionHandlers();
        this.setupVisibilityHandler();
        SocketNotification.instance = this;

        // Only initialize if premium
        if (this.isPremium) {
            this.initialize();
        }
    }

    private setupConnectionHandlers(): void {
        this.on('connect', () => {
            console.log('Notification socket connected');
            this.isConnected = true;
            this.reattachEventListeners();
        });

        this.on('disconnect', () => {
            console.log('Notification socket disconnected');
            this.isConnected = false;
        });

        this.on('connect_error', (error: any) => {
            console.error('Notification socket connection error:', error);
            this.cleanup();
        });
    }

    private setupVisibilityHandler(): void {
        if (typeof document !== 'undefined') {
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {
                    if (this.isPremium) {  // Only reconnect if premium
                        this.reconnect();
                    }
                } else {
                    this.cleanup();
                }
            });
        }
    }

    initialize(): boolean {
        if (this.initialized) {
            return true;
        }

        if (!this.isPremium) {
            console.log('Socket connection not initialized: User is not premium');
            return false;
        }

        try {
            this.connect();
            this.initialized = true;
            return true;
        } catch (error) {
            console.error('Failed to initialize socket:', error);
            return false;
        }
    }

    safeOn(event: string, callback: Function): void {
        if (!this.isPremium) {
            console.log('Event subscription ignored: User is not premium');
            return;
        }
        this.eventSubscriptions.push({ event, callback });
        this.on(event, callback as any);
    }

    safeOff(event: string): void {
        this.removeAllListeners(event);
        this.eventSubscriptions = this.eventSubscriptions.filter(sub => sub.event !== event);
    }

    private reattachEventListeners(): void {
        if (!this.isPremium) return;

        this.eventSubscriptions.forEach(sub => {
            this.removeAllListeners(sub.event);
            this.on(sub.event, sub.callback as any);
        });
    }

    reconnect(): void {
        if (!this.isPremium) {
            console.log('Reconnect ignored: User is not premium');
            return;
        }

        if (!this.isConnected && this.initialized) {
            this.cleanup();
            this.connect();
        }
    }

    cleanup(): void {
        this.disconnect();
        this.removeAllListeners();
        this.eventSubscriptions = [];
        this.isConnected = false;
    }

    static clearInstance(): void {
        if (SocketNotification.instance) {
            SocketNotification.instance.cleanup();
            SocketNotification.instance = null;
        }
    }
}

@NgModule({
    declarations: [AppComponent, LoaderInterceptorComponent, ImportentDeadlineComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CarouselModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ToastrModule.forRoot({ preventDuplicates: true }),
        ChatModule,
        // SocketIoModule.forRoot(config),
        SweetAlert2Module.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        DatePipe,
        SpecialCharPipe,
        SocketNotification,
        { provide: RouteReuseStrategy, useClass: DynamicRouteReuseStrategy }
        // SocketChat,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
