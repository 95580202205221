import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    constructor(private toastr: ToastrService) {}

    // showSuccess(title: string, message: string, options?: IndividualConfig) {
    //     this.toastr.success(message, title, options);
    // }

    showWarnig(title: string, message: string, options?: IndividualConfig) {
        this.toastr.warning(message, title, options);
    }

    // showInfo(title: string, message: string, options?: IndividualConfig) {
    //     this.toastr.info(message, title, options);
    // }

    // showError(title: string, message: string, options?: IndividualConfig) {
    //     this.toastr.error(message, title, options);
    // }

    showSuccess(title: string, message: string, onConfirmAction = () => {}) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'success',
            confirmButtonText: 'OK',
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,     
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirmAction(); // Call your method here
            }
        });
    }

    showWarning(title: string, message: string) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            confirmButtonText: 'OK',
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,     
        });
    }

    showInfo(title: string, message: string) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'info',
            confirmButtonText: 'OK',
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,     
        });
    }

    showError(title: string, message: string) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,     
        });
    }
    showErrorTWo(title: string, message: string) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,
            customClass: {
                title: 'swal-title-custom' // Custom class added
            }       
        });
    }

    showSuccessForTime(title: string, message: string, onConfirmAction = () => {}) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'success',
            confirmButtonText: 'OK',
            timer: 2000,
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirmAction(); // Call your method here
            }
        });

        // setTimeout(() => {
        //     onConfirmAction();
        // }, 2000);
    }

    showErrorCustom(title: string, message: string) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Reset Password',
            customClass: {
                cancelButton: 'swal-reset-button'  // custom class for "Reset Password" button
            },
            allowOutsideClick: false, // Prevents closing on outside click
            allowEscapeKey: false,    // Prevents closing on 'Escape' key press
            allowEnterKey: false,     
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                // Handle the Reset Password action
                window.location.replace('/authentication/forgot-password')
            }
        });;
    }
}
